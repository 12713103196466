export const CURRENT_YEAR = new Date().getFullYear() - 1

export const TOPICS: { [key: string]: string } = {
  accessibility: 'Accessibility',
  android: 'Android',
  api: 'API',
  cfml: 'ColdFusion/CFML',
  clojure: 'Clojure',
  cpp: 'C++',
  css: 'CSS',
  data: 'Data / AI',
  devops: 'DevOps',
  dotnet: '.NET',
  elixir: 'Elixir',
  elm: 'Elm',
  general: 'General',
  golang: 'Golang',
  graphql: 'GraphQL',
  groovy: 'Groovy',
  haskell: 'Haskell',
  identity: 'Identity',
  ios: 'iOS / Swift',
  iot: 'IoT / Hardware',
  java: 'Java',
  javascript: 'JavaScript',
  kotlin: 'Kotlin',
  leadership: 'Leadership',
  networking: 'Community / Networking',
  opensource: 'Open Source',
  performance: 'Performance',
  php: 'PHP',
  product: 'Product',
  python: 'Python',
  ruby: 'Ruby',
  rust: 'Rust',
  scala: 'Scala',
  security: 'Security',
  sre: 'SRE',
  testing: 'Testing',
  typescript: 'TypeScript',
  'tech-comm': 'Content strategy',
  ux: 'Design / UX',
}

export const LOCALES: { [key: string]: string } = {
  AF: 'Afrikaans',
  AK: 'Akan',
  AM: 'Amharic',
  AR: 'Arabic',
  AS: 'Assamese',
  ASA: 'Asu',
  AZ: 'Azerbaijani',
  BE: 'Belarusian',
  BEM: 'Bemba',
  BEZ: 'Bena',
  BG: 'Bulgarian',
  BM: 'Bambara',
  BN: 'Bengali',
  BO: 'Tibetan',
  BS: 'Bosnian',
  CA: 'Catalan',
  CGG: 'Chiga',
  CHR: 'Cherokee',
  CS: 'Czech',
  CY: 'Welsh',
  DA: 'Danish',
  DAV: 'Taita',
  DE: 'German',
  EBU: 'Embu',
  EE: 'Ewe',
  EL: 'Greek',
  EN: 'English',
  EO: 'Esperanto',
  ES: 'Spanish',
  ET: 'Estonian',
  EU: 'Basque',
  FA: 'Persian',
  FF: 'Fulah',
  FI: 'Finnish',
  FIL: 'Filipino',
  FO: 'Faroese',
  FR: 'French',
  GA: 'Irish',
  GL: 'Galician',
  GSW: 'Swiss German',
  GU: 'Gujarati',
  GUZ: 'Gusii',
  GV: 'Manx',
  HA: 'Hausa',
  HAW: 'Hawaiian',
  HE: 'Hebrew',
  HI: 'Hindi',
  HR: 'Croatian',
  HU: 'Hungarian',
  HY: 'Armenian',
  ID: 'Indonesian',
  IG: 'Igbo',
  II: 'Sichuan Yi',
  IS: 'Icelandic',
  IT: 'Italian',
  JA: 'Japanese',
  JMC: 'Machame',
  KA: 'Georgian',
  KAB: 'Kabyle',
  KAM: 'Kamba',
  KDE: 'Makonde',
  KEA: 'Kabuverdianu',
  KHQ: 'Koyra Chiini',
  KI: 'Kikuyu',
  KK: 'Kazakh',
  KL: 'Kalaallisut',
  KLN: 'Kalenjin',
  KM: 'Khmer',
  KN: 'Kannada',
  KO: 'Korean',
  KOK: 'Konkani',
  KW: 'Cornish',
  LAG: 'Langi',
  LG: 'Ganda',
  LT: 'Lithuanian',
  LUO: 'Luo',
  LUY: 'Luyia',
  LV: 'Latvian',
  MAS: 'Masai',
  MER: 'Meru',
  MFE: 'Morisyen',
  MG: 'Malagasy',
  MK: 'Macedonian',
  ML: 'Malayalam',
  MR: 'Marathi',
  MS: 'Malay',
  MT: 'Maltese',
  MY: 'Burmese',
  NAQ: 'Nama',
  NB: 'Norwegian Bokmål',
  ND: 'North Ndebele',
  NE: 'Nepali',
  NL: 'Dutch',
  NN: 'Norwegian Nynorsk',
  NYN: 'Nyankole',
  OM: 'Oromo',
  OR: 'Oriya',
  PA: 'Punjabi',
  PL: 'Polish',
  PS: 'Pashto',
  PT: 'Portuguese',
  RM: 'Romansh',
  RO: 'Romanian',
  ROF: 'Rombo',
  RU: 'Russian',
  RW: 'Kinyarwanda',
  RWK: 'Rwa',
  SAQ: 'Samburu',
  SEH: 'Sena',
  SES: 'Koyraboro Senni',
  SG: 'Sango',
  SHI: 'Tachelhit',
  SI: 'Sinhala',
  SK: 'Slovak',
  SL: 'Slovenian',
  SN: 'Shona',
  SO: 'Somali',
  SQ: 'Albanian',
  SR: 'Serbian',
  SV: 'Swedish',
  SW: 'Swahili',
  TA: 'Tamil',
  TE: 'Telugu',
  TEO: 'Teso',
  TH: 'Thai',
  TI: 'Tigrinya',
  TO: 'Tonga',
  TR: 'Turkish',
  TZM: 'Central Morocco Tamazight',
  UK: 'Ukrainian',
  UR: 'Urdu',
  UZ: 'Uzbek',
  VI: 'Vietnamese',
  VUN: 'Vunjo',
  XOG: 'Soga',
  YO: 'Yoruba',
  YUE: 'Cantonese',
  ZH: 'Chinese',
  ZU: 'Zulu',
}
